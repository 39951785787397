
    import { Component, Prop, mixins } from 'nuxt-property-decorator';
    import ImageMixin from '@/mixins/imageMixin';
    import ImageComponent from '@/components/blocks/ImageComponent.vue';
    import OptionsFieldsInlineEditor from '@/components/editor/options/fields/OptionsFieldsInlineEditor.vue';
    import { AvailableGroups, AvailableTypes } from '@/components/editor/config/types';
    import { optionsDefault } from '@/components/editor/config/optionsDefault';
    import BlogComponentWrapper from '@/components/blocks/blog/BlogComponentWrapper.vue';
    import PaletteMixin from '@/mixins/paletteMixin';

    export const meta = {
        title: 'Блог. Цитаты',
        options: {
            items: {
                title: 'Цитаты',
                type: AvailableTypes.array,
                item: {
                    image: {
                        type: AvailableTypes.image,
                        title: 'Фотография'
                    },
                    author: {
                        type: AvailableTypes.string,
                        title: 'Автор'
                    },
                    profession: {
                        type: AvailableTypes.string,
                        title: 'Профессия'
                    },
                    quoteText: {
                        type: AvailableTypes.string,
                        title: 'Цитата'
                    }
                },
                default: optionsDefault.BlogQuotes
            },
            additionalComponent: {
                type: AvailableTypes.additionalComponent
            }
        },
        group: AvailableGroups.Блог
    };

    @Component({
        components: {
            BlogComponentWrapper,
            OptionsFieldsInlineEditor,
            ImageComponent
        }
    })
    export default class BlockQuotes extends mixins(PaletteMixin, ImageMixin) {
        @Prop() component;
        @Prop() items;
        @Prop() additionalComponent;
    }
