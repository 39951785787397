
    import { Component, mixins, Prop, Watch } from 'nuxt-property-decorator';
    import PaletteMixin from '@/mixins/paletteMixin';
    import OptionsFieldsInlineEditor from '@/components/editor/options/fields/OptionsFieldsInlineEditor.vue';
    import { AvailableGroups, AvailableTypes } from '@/components/editor/config/types';
    import BlogComponentWrapper from '@/components/blocks/blog/BlogComponentWrapper.vue';

    export const meta = {
        title: 'Блог. Таблица в две колонки - 1 вариант',
        options: {
            firstColumnTitle: {
                title: 'Заголовок первой колонки',
                type: AvailableTypes.editor,
                default: 'Плюсы'
            },
            firstColumn: {
                title: 'Первая колонка',
                type: AvailableTypes.arrayOfType,
                item: {
                    type: AvailableTypes.editor,
                    title: 'Текст',
                    default: 'печать неощутима на ткани'
                }
            },
            secondColumnTitle: {
                title: 'Заголовок второй колонки',
                type: AvailableTypes.editor,
                default: 'Минусы'
            },
            secondColumn: {
                title: 'Вторая колонка',
                type: AvailableTypes.arrayOfType,
                item: {
                    type: AvailableTypes.editor,
                    title: 'Текст',
                    default: 'самый сложный и поэтому дорогой способ шелкографии'
                }
            },
            bottomText: {
                title: 'Текст в нижней части таблицы',
                type: AvailableTypes.editor,
                default:
                    'Минимальный тираж: до 4 цветов — 100 шт., более 5 цветов — 150-200 шт.'
            },
            additionalComponent: {
                type: AvailableTypes.additionalComponent
            }
        },
        group: AvailableGroups.Блог
    };

    @Component({
        components: {
            OptionsFieldsInlineEditor,
            BlogComponentWrapper
        }
    })
    export default class BlogTwoColumnsTableFirst extends mixins(PaletteMixin) {
        @Prop() component;
        @Prop() firstColumnTitle;
        @Prop() secondColumnTitle;
        @Prop() firstColumn;
        @Prop() secondColumn;
        @Prop() bottomText;
        @Prop() additionalComponent;

        rows = 0;

        @Watch('firstColumn', { immediate: true, deep: true })
        @Watch('secondColumn', { immediate: true, deep: true })
        updateRows() {
            this.rows =
                this.firstColumn.length >= this.secondColumn.length
                    ? this.firstColumn.length
                    : this.secondColumn.length;

            this.$forceUpdate();
        }
    }
