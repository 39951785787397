import { render, staticRenderFns } from "./BlogFourPicturesWithText.vue?vue&type=template&id=dcbd0050&scoped=true&"
import script from "./BlogFourPicturesWithText.vue?vue&type=script&lang=ts&"
export * from "./BlogFourPicturesWithText.vue?vue&type=script&lang=ts&"
import style0 from "./BlogFourPicturesWithText.vue?vue&type=style&index=0&id=dcbd0050&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dcbd0050",
  null
  
)

export default component.exports