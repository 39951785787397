
    import { Component, Prop, Vue } from 'nuxt-property-decorator';
    import {
        AvailableGroups,
        AvailableTypes,
        ComponentMeta
    } from '@/components/editor/config/types';
    import SliderWrapper from '@/components/blocks/SliderWrapper.vue';
    import ImageComponent from '@/components/blocks/ImageComponent.vue';
    import OptionsFieldsInlineEditor from '@/components/editor/options/fields/OptionsFieldsInlineEditor.vue';

    interface BlogSliderBlock extends ComponentMeta {}

    export const meta: BlogSliderBlock = {
        title: 'Блог. Слайдер',
        options: {
            items: {
                type: AvailableTypes.array,
                item: {
                    text: {
                        type: AvailableTypes.editor,
                        title: 'Текст'
                    },
                    img: {
                        type: AvailableTypes.image,
                        title: 'Изображение'
                    }
                }
            }
        },
        group: AvailableGroups.Слайдер
    };
    @Component({
        components: { OptionsFieldsInlineEditor, ImageComponent, SliderWrapper }
    })
    export default class BlogSlider extends Vue {
        @Prop() items;
        @Prop() activeSlide;
    }
