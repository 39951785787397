
    import { Component, Prop, Vue } from 'nuxt-property-decorator';
    import BlogComponentWrapper from '@/components/blocks/blog/BlogComponentWrapper.vue';
    import { AvailableGroups, AvailableTypes } from '@/components/editor/config/types';

    export const meta = {
        title: 'Блог. Блок с видео',
        options: {
            videoId: {
                title: 'ID видео',
                type: AvailableTypes.string,
                default: 'vJ_67lsuK8E'
            },
            additionalComponent: {
                type: AvailableTypes.additionalComponent
            }
        },
        group: AvailableGroups.Блог
    };

    @Component({
        components: { BlogComponentWrapper }
    })
    export default class Video extends Vue {
        @Prop() component;
        @Prop(String) videoId: string;
        @Prop() additionalComponent;

        get src() {
            return `https://www.youtube.com/embed/${this.videoId}`;
        }
    }
