
    import { Component, mixins, Prop, Watch } from 'nuxt-property-decorator';
    import { namespace } from 'vuex-class';
    import PageBlockMixin from '@/mixins/pageBlockMixin';
    import Button from '@/components/blocks/Button.vue';
    import PaletteMixin from '@/mixins/paletteMixin';
    import { PageComponent } from '@/store/page';
    import FormColorNotFound from '@/components/blocks/FormColorNotFound.vue';
    import Color from '@/components/blocks/colors/Color.vue';
    import Modal from '@/components/blocks/Modal.vue';
    import BaseTabs from '@/components/blocks/base/BaseTabs.vue';
    import OptionsFieldsInlineEditor from '@/components/editor/options/fields/OptionsFieldsInlineEditor.vue';
    import WindowMixin from '@/mixins/windowMixin';

    export interface IColor {
        color: string;
        name?: string;
        active?: boolean;
    }

    export interface IManufacturer {
        title: string;
        colors: IColor[];
        active?: boolean;
    }

    const entities = namespace('entities');
    const settings = namespace('settings');

    @Component({
        components: {
            OptionsFieldsInlineEditor,
            BaseTabs,
            Modal,
            Color,
            FormColorNotFound,
            Button
        }
    })
    export default class ColorsTabs extends mixins(
        PaletteMixin,
        PageBlockMixin,
        WindowMixin
    ) {
        @Prop() tabs: any;
        @Prop() component: PageComponent;
        @Prop() index;
        @Prop({ default: false }) showDescription;
        @entities.Action fetchAll;
        @entities.State prefetchedEntities;
        @settings.Action get;
        activeColor: null | number = null;
        fetchedTabs: any[] = [];

        async beforeMount() {
            await this.fetchTabs();
        }

        async beforeUpdate() {
            await this.fetchTabs();
        }

        get tabsIds() {
            if (Array.isArray(this.tabs)) {
                return this.tabs.map(tab => tab.colorGroupId || tab);
            } else {
                return [];
            }
        }

        get tabsWithCustomDescription() {
            return this.fetchedTabs.map((tab, index) => {
                return {
                    ...tab,
                    description: this.tabs[index] ? this.tabs[index].description : ''
                };
            });
        }

        @Watch('tabsIds', { immediate: true, deep: true })
        changedTabs() {
            this.fetchTabs(true);
        }

        async fetchTabs(update?: boolean) {
            if ((this.tabs && !this.fetchedTabs) || update) {
                const colors = await this.fetchAll('colors');

                this.fetchedTabs = [];

                this.tabsIds.forEach(id => {
                    const color = colors.find(color => color.id === id);

                    if (color) {
                        this.fetchedTabs.push(color.options);
                    }
                });
            }
        }

        changeColorState(val) {
            this.activeColor = val;
        }

        getClass(colors) {
            if (colors.length > 28 && this.windowWidth < 992) {
                return 'colors-flex-column';
            } else if (colors.length > 20 && this.windowWidth < 768) {
                return 'colors-flex-column';
            } else if (colors.length > 16 && this.windowWidth < 453) {
                return 'colors-flex-column';
            } else if (colors.length > 12 && this.windowWidth < 410) {
                return 'colors-flex-column';
            }
        }
    }
