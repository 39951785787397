var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('b-col',[_c('SliderWrapper',{staticClass:"d-none d-lg-block",attrs:{"slides":_vm.items,"items-in-preview":1,"is-multi-image":true},scopedSlots:_vm._u([{key:"default",fn:function({
                slides,
                previewImages,
                prev,
                next,
                getClass,
                currentIndex,
                goToSlide
            }){return [_c('div',{staticClass:"blog__slider"},[_c('div',{staticClass:"blog-slider__nav row"},[_c('button',{staticClass:"blog-slider__nav-btn left",on:{"click":prev}}),_vm._v(" "),_c('button',{staticClass:"blog-slider__nav-btn right",on:{"click":next}})]),_vm._v(" "),_c('transition-group',{attrs:{"tag":"div"}},_vm._l((previewImages),function({ src, href, text, key, medium },index){return _c('a',{key:key,staticClass:"blog-slider__content",class:{
                            prev: index === 0,
                            active: index > 0 && index < previewImages.length - 1,
                            next: index === previewImages.length - 1,
                            'palette-color-text-hover': href
                        },attrs:{"href":href || undefined}},[_c('image-component',{staticClass:"blog-slider__img",attrs:{"img":{ src, href, text, key, medium }}}),_vm._v(" "),_c('div',{staticClass:"blog-slider__text"},[_c('options-fields-inline-editor',{attrs:{"value":text,"keyOption":`items.${index}.text`}})],1)],1)}),0),_vm._v(" "),_c('div',{staticClass:"blog-slider__paginator"},[_c('b-col',[_vm._v(" "+_vm._s(currentIndex + 1)+"/"+_vm._s(_vm.items.length))])],1)],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }