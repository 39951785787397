var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"four-pics-text"},[_c('BContainer',[_c('BlogComponentWrapper',{attrs:{"component":_vm.additionalComponent}},[_c('div',{staticClass:"col-lg-9 offset-lg-1"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-8"},[_c('div',{staticClass:"row four-pics-text__images-wrap"},_vm._l((_vm.items),function(item,index){return (item.img)?_c('div',{key:index,staticClass:"col-lg-6"},[_c('div',{staticClass:"four-pics-text__item"},[_c('div',{staticClass:"four-pics-text__image"},[(item.img)?_c('image-component',{attrs:{"img":item.img}}):_vm._e()],1)])]):_vm._e()}),0),_vm._v(" "),_c('div',{staticClass:"four-pics-text__slider"},[_c('SliderWrapper',{attrs:{"slides":_vm.items},scopedSlots:_vm._u([{key:"default",fn:function({
                                    slides,
                                    prev,
                                    next,
                                    currentIndex,
                                    getClass
                                }){return [_c('div',{staticClass:"base-slider"},[_c('div',{staticClass:"base-slider__slider"},[_c('div',{staticClass:"base-slider__img-wrap"},_vm._l((slides),function(slide,index){return _c('div',{directives:[{name:"swipe",rawName:"v-swipe:left",value:(next),expression:"next",arg:"left"},{name:"swipe",rawName:"v-swipe:right",value:(prev),expression:"prev",arg:"right"}],key:`slide-${index}`,staticClass:"base-slider__slide",class:getClass(index)},[_c('image-component',{staticClass:"base-slider__img",attrs:{"img":slide.img}})],1)}),0)]),_vm._v(" "),_c('SliderBullets',{staticClass:"base-slider__bullets d-lg-none",attrs:{"slides-amount":slides.length,"current":currentIndex}})],1)]}}])})],1)]),_vm._v(" "),_c('div',{staticClass:"col-lg-4"},[_c('OptionsFieldsInlineEditor',{staticClass:"four-pics-text__text",attrs:{"keyOption":"text","component":_vm.component,"value":_vm.text}})],1)])])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }