
    import { Component, Prop, mixins } from 'nuxt-property-decorator';
    import {
        AvailableGroups,
        AvailableTypes,
        ComponentMeta
    } from '@/components/editor/config/types';
    import ImageComponent from '@/components/blocks/ImageComponent.vue';
    import OptionsFieldsInlineEditor from '@/components/editor/options/fields/OptionsFieldsInlineEditor.vue';
    import Modal from '@/components/blocks/Modal.vue';
    import BlogComponentWrapper from '@/components/blocks/blog/BlogComponentWrapper.vue';
    import PaletteStylesMixin from '@/mixins/paletteStylesMixin';
    import BaseSlider from '@/components/common/BaseSlider.vue';
    import ImageMixin from '@/mixins/imageMixin';
    import striptags from 'striptags';

    export const meta: ComponentMeta = {
        title: 'Блог. Картинки с текстом в 2 и 3 колонки',
        options: {
            title: {
                type: AvailableTypes.editor,
                title: 'Заголовок',
                default: 'Этапы работы над очень срочным заказом'
            },
            threeColumns: {
                type: AvailableTypes.boolean,
                title: '3 колонки',
                default: false
            },
            items: {
                type: AvailableTypes.array,
                item: {
                    img: {
                        type: AvailableTypes.image,
                        title: 'Изображение'
                    },
                    link: {
                        type: AvailableTypes.link,
                        default: {}
                    },
                    text: {
                        type: AvailableTypes.editor,
                        title: 'Текст',
                        default: 'Возьмите ребенка, желательно девочку'
                    },
                    showNumber: {
                        type: AvailableTypes.boolean,
                        title: 'Показывать номер',
                        default: false
                    }
                }
            },
            additionalComponent: {
                type: AvailableTypes.additionalComponent
            }
        },
        group: AvailableGroups.Блог
    };
    @Component({
        components: {
            BlogComponentWrapper,
            Modal,
            OptionsFieldsInlineEditor,
            ImageComponent,
            BaseSlider
        }
    })
    export default class BlogPicsInColumns extends mixins(
        PaletteStylesMixin,
        ImageMixin
    ) {
        @Prop() title;
        @Prop() threeColumns;
        @Prop() items;
        @Prop() component;
        @Prop() additionalComponent;

        striptags = striptags;

        getNumber(index) {
            return index > 10 ? index.toString() : '0' + index.toString();
        }

        modalId = 'modalSlider' + Math.random();
        pictureIndex = null;

        openModal(picIndex) {
            this.pictureIndex = picIndex;
            this.$bvModal.show(this.modalId);
        }
    }
