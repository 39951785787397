
    import { Component, mixins, Prop } from 'nuxt-property-decorator';
    import PaletteMixin from '@/mixins/paletteMixin';
    import OptionsFieldsInlineEditor from '@/components/editor/options/fields/OptionsFieldsInlineEditor.vue';
    import { AvailableGroups, AvailableTypes } from '@/components/editor/config/types';
    import BlogComponentWrapper from '@/components/blocks/blog/BlogComponentWrapper.vue';
    import { optionsDefault } from '@/components/editor/config/optionsDefault';
    import Accordion from '@/components/blocks/Accordion.vue';
    import striptags from 'striptags';

    export const meta = {
        title: 'Блог. Таблица в три колонки',
        options: {
            columnsHeader: {
                type: AvailableTypes.array,
                title: 'Заголовки колонок',
                item: {
                    text: {
                        type: AvailableTypes.editor,
                        title: 'Заголовок'
                    }
                },
                limit: 2
            },
            rows: {
                type: AvailableTypes.array,
                title: 'Строки',
                item: {
                    firstColumn: {
                        type: AvailableTypes.editor,
                        title: 'Первая колонка'
                    },
                    secondColumn: {
                        type: AvailableTypes.editor,
                        title: 'Вторая колонка'
                    },
                    thirdColumn: {
                        type: AvailableTypes.editor,
                        title: 'Третья колонка'
                    }
                }
            },
            default: optionsDefault.BlogThreeColumnsTable,
            additionalComponent: {
                type: AvailableTypes.additionalComponent
            }
        },
        group: AvailableGroups.Блог
    };

    @Component({
        components: {
            Accordion,
            OptionsFieldsInlineEditor,
            BlogComponentWrapper
        }
    })
    export default class BlogThreeColumnsTable extends mixins(PaletteMixin) {
        @Prop() component;
        @Prop() columnsHeader;
        @Prop() rows;
        @Prop() additionalComponent;
        striptags = striptags;

        isNotJoinColumns(row, index) {
            return index === 'firstColumn' ? true : row.secondColumn && row.thirdColumn;
        }

        getHeadersWidth(headIndex) {
            return headIndex === 0 ? 'col-lg-5' : 'col-lg-4';
        }

        getColumnsWidth(row, index) {
            if (index === 'firstColumn') {
                return 'col-lg-3';
            } else if (index === 'secondColumn') {
                return row.thirdColumn !== '' ? 'col-lg-5' : 'col-lg-9';
            } else {
                return 'col-lg-4';
            }
        }

        getIndexedValueFromRow(row, ind) {
            let find;
            Object.entries(row).forEach(([key, item], index) => {
                if (ind + 1 === index) {
                    find = item;
                    return false;
                }
            });

            return find || '';
        }
    }
