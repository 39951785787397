
    import { Component, Prop, mixins } from 'nuxt-property-decorator';
    import { AvailableGroups, AvailableTypes } from '@/components/editor/config/types';
    import ImageComponent from '@/components/blocks/ImageComponent.vue';
    import OptionsFieldsInlineEditor from '@/components/editor/options/fields/OptionsFieldsInlineEditor.vue';
    import BlogComponentWrapper from '@/components/blocks/blog/BlogComponentWrapper.vue';
    import PaletteStylesMixin from '@/mixins/paletteStylesMixin';
    import swipe from '@/directives/swipe';
    import SliderWrapper from '@/components/blocks/SliderWrapper.vue';
    import SliderBullets from '@/components/common/SliderBullets.vue';

    export const meta = {
        title: 'Блог. 4 картинки с текстом',
        options: {
            text: {
                type: AvailableTypes.editor,
                title: 'Текст блока',
                default:
                    '<p>В качестве технологии замены мы предложили сменные полноцветные шевроны на липучке, которые крепятся на плече.</p>' +
                    '<p>Клуб выиграл и болельщики быстро сменили шевроны на «Обладатель кубка».</p>' +
                    '<p>У директора Локо есть заяц-талисман, который уже много лет ездит с клубом на каждую игру. Теперь он тоже носит футболку Обладателя кубка.</p>'
            },
            items: {
                type: AvailableTypes.array,
                limit: 4,
                item: {
                    img: {
                        type: AvailableTypes.image,
                        title: 'Изображение',
                        default: {
                            src: '/block-default/ImageWithText/3shite.jpg'
                        }
                    }
                }
            },
            additionalComponent: {
                type: AvailableTypes.additionalComponent
            }
        },
        group: AvailableGroups.Блог
    };
    @Component({
        components: {
            BlogComponentWrapper,
            OptionsFieldsInlineEditor,
            ImageComponent,
            SliderWrapper,
            SliderBullets
        },
        directives: { swipe }
    })
    export default class BlogFourPicturesWithText extends mixins(PaletteStylesMixin) {
        @Prop() text;
        @Prop() items;
        @Prop() component;
        @Prop({ default: 0 }) startFrom: number;
        @Prop() additionalComponent;
    }
