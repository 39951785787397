
    import { Component, Prop, Vue } from 'nuxt-property-decorator';
    import { AvailableGroups, AvailableTypes } from '@/components/editor/config/types';
    import ImageComponent from '@/components/blocks/ImageComponent.vue';

    export const meta = {
        inner: true,
        title: 'Детальная страница блога. Соцсервисы',
        options: {
            header: {
                title: 'Заголовок',
                type: AvailableTypes.string,
                default: 'Поделиться'
            },
            socials: {
                title: 'Соцсети',
                type: AvailableTypes.array,
                item: {
                    icon: {
                        type: AvailableTypes.image,
                        title: 'Иконка'
                    },
                    link: {
                        type: AvailableTypes.string,
                        title: 'Ссылка'
                    }
                }
            }
        },
        group: AvailableGroups.Блог
    };
    @Component({
        components: { ImageComponent }
    })
    export default class BlogSocial extends Vue {
        @Prop() header;
        @Prop() socials;
    }
