var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"table-block"},[_c('b-container',[_c('blog-component-wrapper',{attrs:{"component":_vm.additionalComponent}},[_c('b-col',{attrs:{"lg":"9","offset-lg":"1"}},[_c('div',{staticClass:"table-block__table",style:(_vm.backgroundBg)},[_c('div',{staticClass:"table-block__head row"},[_c('div',{staticClass:"table-block__cell col-lg-3"}),_vm._v(" "),_vm._l((_vm.columnsHeader),function(head,headIndex){return _c('div',{key:headIndex,staticClass:"table-block__cell col-lg-4",class:_vm.getHeadersWidth(headIndex)},[_c('div',{staticClass:"cell__head"},[_c('options-fields-inline-editor',{attrs:{"component":_vm.component,"keyOption":`columnsHeader.${headIndex}.text`,"value":head.text}})],1)])})],2),_vm._v(" "),_vm._l((_vm.rows),function(row,rowIndex){return _c('div',{key:rowIndex,staticClass:"table-block__row row"},_vm._l((row),function(cell,cellIndex){return _c('div',{key:cellIndex},[(cell)?_c('div',{class:[
                                    'table-block__cell',
                                    _vm.getColumnsWidth(row, cellIndex)
                                ]},[_c('div',{staticClass:"cell__item"},[_c('options-fields-inline-editor',{attrs:{"component":_vm.component,"keyOption":`rows.${rowIndex}.${cellIndex}`,"value":cell}})],1)]):_vm._e()])}),0)})],2),_vm._v(" "),_c('div',{staticClass:"table-block__table table-block__table_mobile",style:(_vm.backgroundBg)},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"accordion-options accordion"},[_c('div',{staticClass:"accordion-options__items-wrap accordion-options__items-wrap_big"},_vm._l((_vm.rows),function(row,rowIndex){return _c('accordion',{key:rowIndex,attrs:{"index":rowIndex}},[_c('template',{slot:"header"},[_vm._v("\n                                                "+_vm._s(_vm.striptags(row.firstColumn))+"\n                                            ")]),_vm._v(" "),_c('template',{slot:"body"},[_c('div',{staticClass:"table-block__content"},[(
                                                            _vm.isNotJoinColumns(row, '')
                                                        )?_vm._l((_vm.columnsHeader),function(header,index){return _c('div',{key:index},[_vm._l((_vm.columnsHeader),function(header,index){return _c('div',{key:index,staticClass:"content__head"},[_vm._v("\n                                                                "+_vm._s(_vm.striptags(
                                                                        header.text
                                                                    ))+"\n                                                            ")])}),_vm._v(" "),_c('div',{staticClass:"content__item"},[_vm._v("\n                                                                "+_vm._s(_vm.striptags(
                                                                        _vm.getIndexedValueFromRow(
                                                                            row,
                                                                            index
                                                                        )
                                                                    ))+"\n                                                            ")])],2)}):[_c('div',{staticClass:"content__head"},_vm._l((_vm.columnsHeader),function(header,index){return _c('span',{key:index},[_vm._v("\n                                                                "+_vm._s(_vm.striptags(
                                                                        header.text
                                                                    ))+"\n                                                                "),(
                                                                        index <
                                                                            _vm.columnsHeader.length -
                                                                                1
                                                                    )?[_vm._v("/")]:_vm._e()],2)}),0),_vm._v(" "),_c('div',{staticClass:"content__item"},[(
                                                                    row.secondColumn
                                                                )?[_vm._v("\n                                                                "+_vm._s(_vm.striptags(
                                                                        row.secondColumn
                                                                    ))+"\n                                                            ")]:[_vm._v("\n                                                                "+_vm._s(_vm.striptags(
                                                                        row.thirdColumn
                                                                    ))+"\n                                                            ")]],2)]],2)])],2)}),1)])])])])])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }