
    import { Component, Prop, Vue } from 'nuxt-property-decorator';
    import { AvailableGroups, AvailableTypes } from '@/components/editor/config/types';

    export const meta = {
        inner: true,
        title: 'Детальная страница блога. Содержание.',
        options: {
            header: {
                title: 'Заголовок',
                type: AvailableTypes.string,
                default: 'Содержание'
            },
            links: {
                title: 'Ссылки',
                type: AvailableTypes.array,
                item: {
                    text: {
                        type: AvailableTypes.string,
                        title: 'Текст',
                        default: ''
                    },
                    id: {
                        type: AvailableTypes.string,
                        title: 'id элемента',
                        default: ''
                    }
                }
            }
        },
        group: AvailableGroups.Блог
    };

    @Component
    export default class BlogTableContents extends Vue {
        @Prop() header;
        @Prop() links;

        scrollToElem(id) {
            const element = document.querySelector(id);
            if (element) {
                element.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start'
                });
            }
        }

        get filteredLinks() {
            return this.links ? this.links.filter(link => !!link.text) : [];
        }
    }
