
    import { Component, mixins, Prop } from 'nuxt-property-decorator';
    import PaletteMixin from '@/mixins/paletteMixin';
    import OptionsFieldsInlineEditor from '@/components/editor/options/fields/OptionsFieldsInlineEditor.vue';
    import {
        AvailableGroups,
        AvailableTypes,
        ComponentMeta
    } from '@/components/editor/config/types';
    import BlogComponentWrapper from '@/components/blocks/blog/BlogComponentWrapper.vue';

    export const meta: ComponentMeta = {
        title: 'Блог. Таблица в две колонки - 2 вариант',
        options: {
            firstColumnTitle: {
                title: 'Заголовок первой колонки',
                type: AvailableTypes.editor,
                default: 'Индивидуализируем'
            },
            firstColumn: {
                title: 'Первая колонка',
                type: AvailableTypes.array,
                item: {
                    text: {
                        title: 'Текст',
                        type: AvailableTypes.editor,
                        default:
                            '<p>Одеваем группу по интересам</p> ' +
                            '<p>Молодежная тусовка хип-хоп, спортсмены.</p>'
                    }
                }
            },
            secondColumnTitle: {
                title: 'Заголовок второй колонки',
                type: AvailableTypes.editor,
                default: 'Усредняем'
            },
            secondColumn: {
                title: 'Вторая колонка',
                type: AvailableTypes.array,
                item: {
                    text: {
                        title: 'Текст',
                        type: AvailableTypes.editor,
                        default:
                            '<p>Футболки для массового мероприятия</p>' +
                            '<p>Вы не знаете состав участников, собрать размерный ряд невозможно. Важно подобрать такой тип лекал, который будет максимально универсальным.</p>'
                    }
                }
            },
            additionalComponent: {
                type: AvailableTypes.additionalComponent
            }
        },
        group: AvailableGroups.Блог
    };

    @Component({
        components: {
            OptionsFieldsInlineEditor,
            BlogComponentWrapper
        }
    })
    export default class BlogTwoColumnsTableSecond extends mixins(PaletteMixin) {
        @Prop() component;
        @Prop() firstColumnTitle;
        @Prop() secondColumnTitle;
        @Prop() firstColumn;
        @Prop() secondColumn;
        @Prop() additionalComponent;

        get firstColRows() {
            return this.firstColumn.length;
        }

        get secondColRows() {
            return this.secondColumn.length;
        }
    }
