
    import { Component, Prop, Vue } from 'nuxt-property-decorator';
    import { getAdditionalComponents } from '@/components';

    @Component({
        components: getAdditionalComponents()
    })
    export default class BlogComponentWrapper extends Vue {
        @Prop() component;
    }
