
    import { Component, Prop, Vue } from 'nuxt-property-decorator';
    import { AvailableGroups, AvailableTypes } from '@/components/editor/config/types';
    import OptionsFieldsInlineEditor from '@/components/editor/options/fields/OptionsFieldsInlineEditor.vue';
    import BlogComponentWrapper from '@/components/blocks/blog/BlogComponentWrapper.vue';

    export const meta = {
        title: 'Текст по центру',
        options: {
            text: {
                title: 'Текст',
                type: AvailableTypes.editor,
                default:
                    '<p>Рубашка-поло — это трикотажная модернизированная футболка из дышащего хлопкового материала «пике», с короткими рукавами, отложным эластичным воротничком и короткой планкой с пуговицами на груди. Поло напоминает классическую рубашку и давно стала обязательным элементом стиля casual. </p>' +
                    '<p>Легки в уходе и приятны в носке. Они, в отличие от классической рубашки, сочетаются практически со всем: от спортивных шорт летом до строгих офисных костюмов. Неудивительно, что поло часто выбирают в качестве корпоративной одежды. </p>' +
                    '<p>К нам часто обращаются клиенты, которые хотят сделать рубашку-поло уникальной и узнаваемой, подчеркнуть свой фирменный стиль и выделиться на фоне конкурентов. К сожалению, часто оказывается, что их дизайны хороши только на бумаге, но их невозможно выполнить технологически или это экономически нецелесообразно.</p>'
            },
            additionalComponent: {
                type: AvailableTypes.additionalComponent
            }
        },
        group: AvailableGroups.Блог
    };
    @Component({
        components: { BlogComponentWrapper, OptionsFieldsInlineEditor }
    })
    export default class BlogMainText extends Vue {
        @Prop() text;
        @Prop() component;
        @Prop() additionalComponent;
    }
