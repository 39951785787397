
    import { Component, namespace, Prop } from 'nuxt-property-decorator';
    import { mixins } from 'nuxt-property-decorator';
    import { AvailableGroups, AvailableTypes } from '@/components/editor/config/types';
    import PaletteMixin from '@/mixins/paletteMixin';
    import ImageMixin from '@/mixins/imageMixin';
    import OptionsFieldsInlineEditor from '@/components/editor/options/fields/OptionsFieldsInlineEditor.vue';
    import ImageComponent from '@/components/blocks/ImageComponent.vue';
    import BlogComponentWrapper from '@/components/blocks/blog/BlogComponentWrapper.vue';
    import BreadCrumbs from '@/components/blocks/BreadCrumbs.vue';
    import { formatDate } from '@/utils';

    export const meta = {
        title: 'Главный блок. Блог 1',
        options: {
            hidePath: {
                title: 'Скрывать хлебные крошки',
                type: AvailableTypes.boolean,
                default: false
            },
            header: {
                title: 'Текст заголовка',
                type: AvailableTypes.editor,
                default: 'Делаем поло уникальной и узнаваемой'
            },
            text: {
                title: 'Текст',
                type: AvailableTypes.editor,
                default:
                    'В этой статье мы расскажем, как можно разнообразить дизайн стандартной поло, поможем сориентироваться в возможностях рынка и предложим несколько идей, которые, надеемся, вас вдохновят.'
            },
            mainImage: {
                title: 'Заглавное изображение',
                type: AvailableTypes.image,
                default: {
                    src: '/block-default/BlogHeader/blog-header.jpg'
                }
            },
            date: {
                title: 'Дата',
                type: AvailableTypes.date,
                default: '2020-01-01'
            },
            additionalComponent: {
                type: AvailableTypes.additionalComponent
            }
        },
        group: AvailableGroups.Блог
    };
    const collections = namespace('collections');
    @Component({
        components: {
            BlogComponentWrapper,
            ImageComponent,
            OptionsFieldsInlineEditor,
            BreadCrumbs
        }
    })
    export default class BlogHeader extends mixins(PaletteMixin, ImageMixin) {
        @Prop() component;
        @Prop() mainImage;
        @Prop() header;
        @Prop() hidePath;
        @Prop() text;
        @Prop() date;
        @Prop() socials;
        @Prop() tableContents;
        @Prop() additionalComponent;
        @collections.Action get;

        get formattedDate() {
            return formatDate(this.date);
        }
    }
