
    import { Component, mixins, Prop } from 'nuxt-property-decorator';
    import PaletteMixin from '@/mixins/paletteMixin';
    import {
        AvailableGroups,
        AvailableTypes,
        ComponentMeta
    } from '@/components/editor/config/types';
    import OptionsFieldsInlineEditor from '@/components/editor/options/fields/OptionsFieldsInlineEditor.vue';
    import BlogComponentWrapper from '@/components/blocks/blog/BlogComponentWrapper.vue';

    export const meta: ComponentMeta = {
        title: 'Блог. Содержание',
        options: {
            header: {
                title: 'Заголовок',
                type: AvailableTypes.editor,
                default: 'Содержание'
            },
            links: {
                title: 'Ссылки',
                type: AvailableTypes.array,
                item: {
                    title: {
                        title: 'Название раздела',
                        type: AvailableTypes.string,
                        default: 'Стандартные виды нанесения – вышивка и шелкография'
                    },
                    component: {
                        title: 'Якорная ссылка',
                        type: AvailableTypes.componentsList
                    }
                }
            }
        },
        group: AvailableGroups.Блог
    };

    @Component({
        components: {
            OptionsFieldsInlineEditor,
            BlogComponentWrapper
        }
    })
    export default class BlogContentNav extends mixins(PaletteMixin) {
        @Prop() component;
        @Prop() header;
        @Prop() links;

        scrollToElem(id) {
            const element = document.querySelector(id);
            if (element) {
                element.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start'
                });
            }
        }
    }
